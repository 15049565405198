<template>
  <div id="ZeroExchange">
    <div :style="`min-height: ` + windowHeight" class="content"></div>
    <!--        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210510102518704.png" class="exch-img1"/>-->
    <div class="vouchers">
      <div style="text-align: start" class="tit" @click="jumpPage('/zero/list',1)">
        我的兑换红包<span>查看红包明细&nbsp;></span>
      </div>
      <div class="wallet_box" v-if="balanceInfo">
        <div>
          <span>剩余金额</span>
          <span style="font-size: 24px;margin-top: 3px">￥{{ balanceInfo.redBalance }}</span>
        </div>
        <div class="wallet_box_haved">
          <span>已使用</span>
          <span style="font-size: 24px;margin-top: 3px">￥{{ balanceInfo.redUsedBalance }}</span>
        </div>
      </div>
      <div class="attention"><span>注意:</span>{{purchaseNotice}}</div>
    </div>
    <div class="main top">
      <p style="text-align: start;margin-left: 14px;font-size: 14px;color: #666;margin-top: 5px;font-weight: bold;margin-bottom: 10px" class="tit1">选择兑换商品</p>
      <div class="empty-data" v-if="finished && productList.length <= 0">
        <img src="../../../assets/images/empty/content.png" />
        <p>暂无数据</p>
      </div>
      <ul class="goods-list" v-else>
        <li v-for="(item,index) in productList" :key="index">
          <div @click="getProductById(item.good_id)">
            <div class="shop-img">
              <img class="mui-media-object" :src="item.good_urls">
              <img v-if="item.selloutImg" :src="item.selloutImg" class="selloutImg" alt="">
            </div>
            <div class="mui-media-body">
              <p style="text-align: start" class="ellipsis1 name">{{ item.good_name }}</p>
              <p style="text-align: start" class="ml-num ellipsis1">{{ item.specifications }}</p>
              <p style="text-align: start" class="prod-txt ellipsis1">{{ item.actEffect }}</p>
              <p style="text-align: start" class="price">
                <span>￥</span>{{ item.good_price }}<em></em>
              </p>
            </div>
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210223111548911.png" class="shop_bg">
          </div>
          <van-stepper v-model="item.CartCount" class="buy-box" :disabled="item.stockNum <= 0" :disable-input="true" @plus="numberPlus(item, $event)" @overlimit="handleUnClickBtn(item)" min="0" @change="numberChange(item)" :class="{'hide-minus-btn':item.CartCount <= 0}"></van-stepper>
        </li>
      </ul>
    </div>
    <div class="footer" :class="{'lay':showOrderModal}" @click="showOrderModal = 0">
      <div class="order-data" v-if="showOrderModal" @click.stop="showOrderModal = 1">
        <div class="clear">
          已选商品
          <div @click.stop="clearOrderList">
            <span class="mui-icon icon-ssa" style="font-size: 20px;color: #aaa"></span>
            清空
          </div>
        </div>
        <div class="list-cont" v-if="currentList.length > 0">
          <template v-for="(item,index) in currentList">
            <div class="list" v-if="item.CartCount  >  0" :key="index">
              <div class="tit1 ellipsis1">{{ item.good_name }}</div>
              <div class="pri-num">
                <p>￥<span>{{ item.good_price }}</span></p>
                <van-stepper v-model="item.CartCount" class="buy-box my-box" disable-input="true" min="-1" @change="numberChange(item)"></van-stepper>
              </div>
            </div>
          </template>
        </div>
      </div>
      <nav class="mui-bar mui-bar-tab" v-if="cartBoxInfo">
        <div class="cart_logo_box">
          <div class="img_box">
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210401142052024.png" class="cart_logo_img2" alt="">
          </div>
          <div class="cart_num_box">{{cartBoxInfo.allNum}}</div>
        </div>
        <div class="car-text">
          <div class="car-money"><s style="text-decoration: none">总价</s>￥{{ cartBoxInfo.allAmount }}</div>
          <p data-lan="TotalValue">已用兑换红包￥{{ vouchersPrice }}
            <span v-if="cartBoxInfo.payAmount > 0">，需支付<span class="pink">￥{{ cartBoxInfo.payAmount }}</span></span>
          </p>
        </div>
        <button type="button" class="mui-btn settlement" @click.stop="goSettlement">去结算</button>
      </nav>
    </div>
    <div class="AnimaToCart" ref="animalToCart"></div>
    <div class="layer" v-if="!!isShow">
      <div class="hint-cont">
        <div class="title1">提示</div>
        <span class="close" @click="isShow = 0">×</span>
        <p>本次使用用团购兑换红包金额<span>{{ vouchersPrice }}元</span>，您选择兑换的商品总价为<span>{{ cartBoxInfo.allAmount }}元</span>。
        </p>
        <p class="pad-top">团购红包不支持找零，可以补差。</p>
        <div class="btn">
          <span @click="showTips">确认兑换</span>
          <span class="active" @click="isShow = 0">再看看</span>
        </div>
      </div>
    </div>

    <div v-if="notPayMsg" class="overlay">
      <div class="container">
        <div class="title">提示</div>
        <div class="content_words">{{notPayMsg}}</div>
        <div @click="routerChange" class="look_btn">去查看兑换订单</div>
      </div>
    </div>
    <toolbar />

    <div v-if="caigouLayer" class="dialog_layer">
      <div class="dialog_layer_box">
        <van-icon size="22" @click="caigouLayer = false" style="position: absolute;right: 8px;top: 10px" color="#999" name="close" />
        <div class="dialog_layer_title" style="font-size: 16px">提示</div>
        <div style="color: #999;margin-top: 19px;box-sizing: border-box;padding: 0 20px">{{tipsWords}}</div>
        <div class="dialog_layer_box_btns">
          <div @click="caigouLayer = false" style="border: 1px solid #ccc">取消</div>
          <div @click="toCaigou" style="background-color: rgb(255, 152, 161);color: #fff">前往采购</div>
        </div>
      </div>
    </div>

    <div v-if="dialogLayer" class="dialog_layer">
      <div class="dialog_layer_box">
        <van-icon size="22" @click="dialogLayer = false" style="position: absolute;right: 8px;top: 10px" color="#999" name="close" />
        <div class="dialog_layer_title" style="font-size: 16px">提示</div>
        <div style="color: #999;margin-top: 8px">当前兑换的商品将随订单：</div>
        <div style="color: #999"><span style="color: rgb(255, 152, 161);">{{agentOrder.agentOrderNum}}</span>一起发货，</div>
        <div style="color: #999">您可在该订单详情中查看</div>
        <div class="dialog_layer_box_btns">
          <div @click="sureExchange" style="border: 1px solid #ccc">确认兑换</div>
          <div @click="dialogLayer = false" style="background-color: rgb(255, 152, 161);color: #fff">取消兑换</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Subtr, accMul, getQueryValue } from "../../../utils/handle";
import toolbar from "../../../components/toolbar";

export default {
  name: "index",
  components: { toolbar },
  data() {
    return {
      windowHeight: '',
      productList: [],
      finished: false,//商品是否加载完
      currentList: [],
      addToCartBtn: null, //添加到购物车的小图标
      cartBoxInfo: {
        allAmount: 0,
        allNum: 0,
        payAmount: 0
      }, // 购物车数据
      vouchersPrice: 0,
      showOrderModal: 0,
      balanceInfo: null,//账户余额信息
      orderNum: getQueryValue("orderNum") || "",//订单编号 用来选中优惠券
      settlementGoods: null,
      isShow: 0, // 模态框
      notPayMsg: '',
      unPayOrderId: '',
      sourceOrderId: '',
      caigouLayer: false,
      tipsWords: '',
      agentOrder: {},
      dialogLayer: false,
      purchaseNotice: ''
    }
  },
  created() {
    // window.location.href = this.baseUrl + '/Base/FairWechatLogin/LoginInfo'
    this.$nextTick(function () {
      this.addToCartBtn = this.$refs.animalToCart;
    });
    document.title = '兑换专区'
    this.windowHeight = document.documentElement.scrollHeight + 'px'
    this.getVouchersList()
    this.getProductList();
    this.getOrderNoPay();
    this.getPurchaseNotice();
  },
  methods: {
    showTips() {
      this.isShow = 0
      this.dialogLayer = true
    },
    getAgentOrderNum() {
      this.get('/OrderGoods/FixedExchange/GetAgentOrderNum', {}, 2).then(data => {
        if (!!data.response.txtMsg) {
          this.tipsWords = data.response.txtMsg
          this.caigouLayer = true
          // return this.$dialog.alert({
          //     title: '提示',
          //     message: data.response.txtMsg
          // }).then(() => {this.$router.back()})
        }
        this.agentOrder = data.response
        // console.log(this.agentOrder)
      })
    },
    routerChange() {
      window.location.href = this.userHost + "/rolorweb/html/recharge/orderDetails.html?orderId=" + this.unPayOrderId + '&type=1'
      // this.$router.push("/Recharge/order_detail?orderId=" + this.unPayOrderId + "&orderType=10")
    },
    //检测有没有未支付的兑换订单
    getOrderNoPay() {
      this.get('/OrderGoods/FixedExchange/GetFixedExchangeOrderUnPay', {}, 2).then(json => {
        if (json && json.code === 1) {
          var data = json.response;
          if (data.isBox) {
            this.notPayMsg = data.msgInfo
            this.unPayOrderId = data.agentOrderId
          } else {
            this.getAgentOrderNum()
          }
        }
      })
    },
    clearOrderList() {

    },
    //获取兑换券列表
    getVouchersList() {
      this.get('/OrderGoods/FixedExchange/GetRedBalance', {}, 2).then(json => {
        if (json.code === 1) {
          this.balanceInfo = json.response || {};
        }
      })
    },
    //获取商品列表
    getProductList() {
      this.$toast.loading()
      this.get('/OrderGoods/FixedExchange/GetFixedGoodList', {}, 2).then(json => {
        this.$toast.clear();
        this.finished = true;
        if (json && json.code === 1) {
          let cartListStr = window.localStorage.cartListStr;
          let zoneList = cartListStr ? JSON.parse(cartListStr) : [];
          let array = json.response;
          for (let i = 0; i < array.length; i++) {
            array[i].CartCount = 0;
            if (array[i].stockNum > 0) {
              for (let n = 0; n < zoneList.length; n++) {
                if (zoneList[n].goodId === array[i].good_id) {
                  array[i].CartCount = zoneList[n].CartCount
                }
              }
            }
          }
          this.productList = array
          this.calculatePrice()
        }
      })
    },
    //计算价格
    calculatePrice() {
      let allAmount = 0, allNum = 0, payAmount = 0;//总金额、总数量、支付金额
      let list = this.productList || [], array = [];
      for (let i = 0; i < list.length; i++) {
        let d = list[i];
        if (d.CartCount > 0) {
          allAmount = Subtr(allAmount, -accMul(d.good_price, d.CartCount));
          allNum = Subtr(allNum, -d.CartCount);
          array.push({ goodId: list[i].good_id, CartCount: list[i].CartCount })
        }
      }
      let balance = this.balanceInfo ? this.balanceInfo.redBalance : 0;
      this.vouchersPrice = (balance > allAmount) > 0 ? allAmount : balance;
      payAmount = Subtr(allAmount, balance);
      let data = {
        allAmount: allAmount,
        allNum: allNum,
        payAmount: payAmount > 0 ? payAmount : 0
      };
      window.localStorage.cartListStr = JSON.stringify(array);
      this.cartBoxInfo = data;
    },
    //商品数量变化
    numberChange: function (item) {
      this.calculatePrice();
    },
    //点击+按钮
    numberPlus: function (product, e) {
      // console.log(product, e)
      let that = this;
      // var X = e.clientX - e.target.offsetWidth;
      // var Y = e.clientY + e.target.offsetHeight / 2;
      // this.addToCartBtn.style.left = X + "px";
      // this.addToCartBtn.style.bottom = (window.innerHeight - Y) + "px";
      // setTimeout(function () {
      //     addClass(that.addToCartBtn, "moveToBottom");
      // }, 10);
      // setTimeout(function () {
      //     that.addToCartBtn.style.bottom = "-1px";
      //     that.addToCartBtn.style.left = "-1px";
      //     removeClass(that.addToCartBtn, "moveToBottom");
      // }, 500);
    },
    toCaigou() {
      const that = this
      that.$toast.loading({ message: '请稍后...', duration: 0, overlay: true });
      that.post('/TeamAgent/AgentInfo/HomeIdentity_V2').then(e => {
        that.$toast.clear()
        const homelevel = e.response.find(item => item.BrandName == '梵洁诗').homelevel
        if (homelevel < 3) {
          window.location.href = that.baseHost + '/7999/Arongleweb/html/eliteAgent/good_list.html?brandId=3'
        } else {
          window.location.href = that.baseHost + '/7999/Arongleweb/html/groupon/groupon_list.html'
        }
      })
    },
    sureExchange: function () {
      this.dialogLayer = false
      let list = this.productList || [];
      let arr = [];
      list.forEach(it => {
        if (it.CartCount > 0) {
          arr.push({ "goodsId": it.good_id, "goodsCount": it.CartCount })
        }
      });
      this.isShow = false;
      if (arr.length <= 0) return this.$toast("请选择要兑换的商品~");
      if (this.balanceInfo.redBalance < 1) return this.$toast("团购红包金额≥1元时才支持兑换噢！~");
      this.$toast.loading({ message: '提交中...', duration: 0, overlay: true });
      let params = {
        goodsList: JSON.stringify(arr),
        sourceType: 2
      }
      this.post('/OrderGoods/FixedExchange/CreateFixedExchangeOrderV2', params, true, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          window.localStorage.cartListStr = "";
          var data = json.response;
          window.location.href = this.userHost + "/rolorweb/html/recharge/orderDetails.html?orderId=" + data.agentOrderId + '&type=1'
        } else {
          this.$dialog.alert({ title: '提示', message: json.msg });
        }
      })
      // this.$router.push('/zero/confirm?goodList=' + JSON.stringify(arr));
    },
    initPage: function (id) {
      this.settlementGoods = null;
      this.isShow = false;
      this.isRepSub = 0;
      this.currentList = [];
      var list = this.vouchersList;
      for (var k = 0; k < list.length; k++) {
        if (list.id === id) {
          list.splice(k, 1);
        }
      }
      var proList = this.productList || [];
      for (var i = 0; i < proList.length; i++) {
        proList[i].CartCount = 0;
      }
      window.localStorage.cartListStr = "";
    },
    //去结算
    goSettlement() {
      var that = this;
      this.showOrderModal = 0;
      var list = this.productList || [], array = [], obj = null;
      for (var i = 0; i < list.length; i++) {
        var good = list[i];
        if (good.CartCount) {
          array.push({
            good_id: good.good_id,
            good_count: good.CartCount,
            good_price: good.good_price
          });
        }
      }
      if (!array.length) return this.$toast('请选择结算商品');
      this.settlementGoods = array;
      this.isShow = 1
    },
    getUserIdentityNewData() {
      this.post('/TeamAgent/AgentInfo/HomeIdentity_V2').then(json => {
        if (json && json.code == 1) {
          var list = json.response;
          var level = Math.max.apply(Math, list.map(function (o) {
            return o.homelevel
          }));
          if (level > 2) {
            //跳代理专区groupon_list
            window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?brandId=1";
          } else {
            //跳团购
            window.location.href = this.baseHost + "/7999/Arongleweb/html/eliteAgent/good_list.html?brandId=1";
          }
        }
      })
    },
    handleUnClickBtn: function (item) {
      if (item.stockNum <= 0) {
        this.$toast(item.good_name + '产品库存告急，停止兑换')
      }
    },
    jumpPage(path, type) {
      if (!path) return
      if (type === 1) this.$router.push(path)
    },
    //获取否买注意提示语
    getPurchaseNotice: function () {
      this.get('/Base/ConfigAddress/GetWebInformationList?', {
        type: 65
      }, 2).then(json => {
        if (json.code === 1) {
          let list = json.response;
          if (list.length > 0) {
            this.purchaseNotice = list[0].url;
          }

        }
      }, 3);
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
#ZeroExchange {
  overflow: hidden;
  .dialog_layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99999;
    .dialog_layer_box {
      font-size: 14px;
      color: #666;
      background-color: #fff;
      width: 78%;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 10px;
      padding-bottom: 15px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      .dialog_layer_box_btns {
        display: flex;
        margin-top: 19px;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;
        div {
          display: flex;
          box-sizing: border-box;
          width: 90px;
          justify-content: center;
          text-align: center;
          padding: 6px 0px;
          border-radius: 500px;
          align-items: center;
        }
      }
    }
  }
  .content {
    background-color: #d19ef2;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210513110338931.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 100px;
  }

  /*.dialog_layer {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .3);
            z-index: 99999;

            .dialog_layer_box {
                font-size: 14px;
                color: #666;
                background-color: #fff;
                width: 78%;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translateX(-50%);
                padding-top: 10px;
                padding-bottom: 15px;
                box-sizing: border-box;
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 10px;

                .dialog_layer_box_btns {
                    display: flex;
                    margin-top: 19px;
                    justify-content: space-between;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 15px;

                    div {
                        display: flex;
                        box-sizing: border-box;
                        width: 90px;
                        justify-content: center;
                        text-align: center;
                        padding: 6px 0px;
                        border-radius: 500px;
                        align-items: center;
                    }
                }
            }
        }*/

  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;

    .container {
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      padding: 15px;
      border-radius: 15px;
      box-sizing: border-box;
      width: 92%;

      .title {
        color: #666;
        text-align: center;
        font-size: 16px;
      }

      .content_words {
        color: #999;
        margin-top: 15px;
        font-size: 14px;
        max-width: 80%;
        text-align: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }

      .look_btn {
        width: 85%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgb(255, 152, 160);
        color: #fff;
        text-align: center;
        height: 35px;
        line-height: 35px;
        margin-top: 30px;
        border-radius: 550px;
      }
    }
  }

  .AnimaToCart {
    position: fixed;
    bottom: -12px;
    left: -12px;
    width: 12px;
    height: 12px;
    z-index: 99;
    color: #fff;
    text-align: center;
    background-color: #ffa8c6;
    border-radius: 50%;
  }

  .van-stepper {
    input {
      background-color: transparent;
    }
  }

  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #ff9f25;
    border-color: #ff9f25;
    font-size: 14px;
  }

  .layer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 245;

    .hint-cont {
      width: 260px;
      /*height: 200px;*/
      padding-bottom: 20px;
      background: #fff;
      position: absolute;
      left: calc(50% - 130px);
      top: calc(50% - 90px);
      border-radius: 6px;
      text-align: center;

      .title1 {
        font-size: 16px;
        color: #666;
        padding: 15px 0;
      }

      .close {
        font-size: 16px;
        color: #666;
        width: 20px;
        height: 20px;
        background: #f1f1f1;
        border-radius: 50%;
        margin: 10px;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        line-height: 20px;
      }

      p {
        padding: 0 25px;
        font-size: 12px;
        color: #999;

        &.pad-top {
          padding-top: 5px;
        }

        span {
          color: rgb(255, 152, 161);
        }
      }

      .btn {
        margin-top: 15px;

        span {
          font-size: 14px;
          color: #666666;
          border: 1px solid #e5e5e5;
          width: 40%;
          display: inline-block;
          border-radius: 30px;
          height: 31px;
          line-height: 31px;

          &.active {
            background: rgb(255, 152, 161);
            color: #fff;
            border-color: rgb(255, 152, 161);
            margin-left: 5%;
          }
        }
      }
    }
  }

  .exch-img1 {
    width: 68%;
  }

  .vouchers {
    background: #fff;
    margin: 100px 15px 0;
    border-radius: 6px;
    padding: 10px 0 20px;

    .wallet_box {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      div {
        width: 49%;
        flex-direction: column;
        background-color: #fff6fb;
        border: 1px solid #ff9dce;
        display: flex;
        color: #ff48a4;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 3px;
        height: 75px;
      }

      .wallet_box_haved {
        border: 1px solid #fcb11b;
        background-color: #fffcf6;
        color: #f1a000;
      }
    }

    .tit {
      font-size: 14px;
      color: #666;
      padding: 0 15px;

      em {
        font-size: 12px;
        color: #999;
        font-style: normal;
      }

      span {
        font-size: 12px;
        color: #ff48a4;
        float: right;
      }
    }

    .no-vou {
      font-size: 12px;
      color: #999;
      margin: 10px 15px;
      border: 1px dashed #ccc;
      text-align: center;
      line-height: 70px;
      border-radius: 6px;
    }

    ul {
      white-space: nowrap;
      text-align: start;
      overflow-x: auto;
      padding: 10px 15px;

      li {
        border: 1px solid #ff9f25;
        width: 170px;
        padding-right: 5px;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 6px;
        margin-right: 10px;
        position: relative;

        &.active {
          background: #fffbe2;
        }

        &:last-child {
          margin-right: 0;
        }

        .select {
          padding: 6px 8px 0;

          .desc1 {
            display: inline-block;
            padding-left: 5px;
            background: #ff9f25;
            border-radius: 30px;
            width: 50px;
            height: 15px;
            box-sizing: border-box;
            position: relative;
            line-height: 15px;

            img {
              width: 22px;
              height: 12px;
              position: relative;
              top: 1px;
            }

            span {
              font-size: 10px;
              color: #fff;
              padding: 0 5px;
              border-radius: 0 30px 30px 0;
              line-height: 13px;
              display: inline-block;
              position: absolute;
              right: 1px;
              top: 1px;
            }
          }

          .van-checkbox {
            float: right;
          }
        }

        .price {
          font-size: 22px;
          color: #ff9f25;
          padding: 0 8px;
          line-height: 29px;
          margin-bottom: 5px;
          margin-top: 2px;

          .price_remarks {
            font-size: 10px;
            max-width: 70px;
            position: absolute;
            right: 6px;
            z-index: 9;
            bottom: 22px;
            line-height: 14px;
            word-wrap: break-word;
            word-break: break-all;
            white-space: pre-wrap;
          }
        }

        .source {
          font-size: 10px;
          color: #ff9f25;
          padding: 1px 12px 1px 8px;
          background: #fff;
          border-top: 1px dashed #ff9f25;
          border-radius: 0 0 6px 6px;
          position: relative;

          &:before {
            content: "";
            width: 5px;
            height: 5px;
            position: absolute;
            right: 7px;
            top: 7px;
            border-top: 1px solid #ff9f25;
            border-right: 1px solid #ff9f25;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
        }
      }
    }

    .attention {
      text-align: start;
      font-size: 12px;
      color: #ff48a4;
      background: #fff6fb;
      padding: 10px;
      border-radius: 6px;
      margin: 0 10px;

      span {
        background: #ff48a4;
        font-size: 10px;
        color: #fff;
        border-radius: 30px;
        padding: 0 4px;
        margin-right: 3px;
      }
    }
  }

  .main {
    margin: 0 15px 0;
    background: #fff;
    border-radius: 8px;
    border-top: 1px dashed #123556;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -7.5px;
      left: -7.5px;
      width: 15px;
      height: 15px;
      background: #d19ef2;
      border-radius: 50%;
    }

    &:after {
      content: "";
      position: absolute;
      top: -7.5px;
      right: -7.5px;
      width: 15px;
      height: 15px;
      background: #d19ef2;
      border-radius: 50%;
    }

    //position: absolute;
    //width: ~'calc(100% - 30px)';
    //left: 0;
    //right: 0;
    //top: 274px;
    //bottom: 0;
    //overflow: hidden;
    .tit1 {
      font-size: 14px !important;
      text-align: start !important;
      color: #666 !important;
      padding: 20px 15px 10px !important;
    }
    .empty-data {
      padding-bottom: 30px;
    }
    .goods-list {
      //position: absolute;
      //left: 0;
      //right: 0;
      //top: 42px;
      //bottom: 0;
      //overflow-y: auto;
      padding-bottom: 50px;

      li {
        position: relative;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .shop-img {
          position: relative;
          width: 110px;
          height: 110px;
          display: inline-block;
          padding: 10px;
          box-sizing: border-box;

          img {
            width: 100%;
          }

          .selloutImg {
            position: absolute;
            width: 100%;
            transform: scale(0.4);
            left: 0;
            top: 0;
            opacity: 0.8;
          }
        }

        .shop_bg {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .mui-media-body {
          //padding: 10px;
          line-height: 20px;
          width: calc(100% - 120px);
          display: inline-block;
          vertical-align: top;
          position: relative;
          z-index: 12;

          p {
            margin-bottom: 0;

            &.name {
              font-size: 14px;
              font-weight: 600;
              color: #636363;
            }

            &.ml-num {
              font-size: 12px;
              color: #636363;
            }

            &.prod-txt {
              font-size: 12px;
              color: #b5b5b5;
            }

            &.price {
              font-size: 18px;
              color: #ff48a4;
              padding-top: 10px;

              span {
                font-size: 14px;
              }

              em {
                font-size: 12px;
                color: #b5b5b5;
                font-style: normal;
                padding-left: 5px;
              }

              s {
                font-size: 12px;
                color: #fff;
                background: #8830cc;
                border-radius: 20px;
                padding: 1px 13px;
                float: right;
              }
            }
          }
        }

        .buy-box {
          position: absolute;
          right: 0;
          bottom: 20px;
          width: 100px;
          border: 0;
          padding: 0;
          z-index: 99;
          background-color: inherit;
          -webkit-transition: all 1s ease-out;
          -moz-transition: all 1s ease-out;

          input {
            margin: 0;
            width: 79%;
            color: #666;
            border: none;
            padding: 0 22px;
            height: 30px;
            box-sizing: border-box;
            display: inline-block;
            -webkit-text-fill-color: #666;
            -webkit-opacity: 1;
            position: relative;
            top: 5px;
          }

          button {
            position: absolute;
            z-index: 6;
            padding: 0;
            height: 100%;
            border: none;
            font-size: 22px;
            background-color: #fff0;

            &:before {
              top: 4px;
              width: auto;
              height: auto;
              background-color: transparent;
              font-family: rolormd !important;
            }
          }

          .van-stepper__plus {
            right: 0;
            color: #ff3e6e;

            &:before {
              content: "\e96c";
              position: relative;
              left: -9px;
              top: 5px;
            }

            &:after {
              width: auto;
              height: auto;
              background-color: transparent;
            }

            &.van-stepper__plus--disabled {
              opacity: 0.6;
            }
          }

          .van-stepper__minus {
            color: #aaa;

            &:before {
              content: "\ea10";
              position: relative;
              top: 6px;
              left: 0;
            }

            &.van-stepper__minus--disabled {
              opacity: 0.6;
            }
          }

          &.hide-minus-btn {
            .van-stepper__minus {
              opacity: 0;
            }

            .van-stepper__input {
              opacity: 0;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }

  .footer {
    &.lay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 240;
    }

    .order-data {
      background: #fff;
      z-index: 12;
      position: absolute;
      width: 100%;
      bottom: 51px;
      max-height: 60%;
      left: 0;
      right: 0;
      overflow-y: auto;

      .hint {
        font-size: 12px;
        color: #333;
        background: #ffe8e8;
        padding: 6px 0;
        text-align: center;
      }

      .clear {
        font-size: 18px;
        color: #999;
        padding: 10px 15px;

        div {
          font-size: 12px;
          color: #999;
          display: inline-block;
          float: right;

          span {
            position: relative;
            top: 2px;
          }
        }
      }

      .list-cont {
        height: calc(100% - 130px);

        .list {
          padding: 5px 15px;
          position: relative;
          font-size: 0;

          &:last-child {
            padding-bottom: 30px;
          }

          .tit1 {
            font-size: 12px;
            color: #333;
            width: 50%;
            display: inline-block;
            position: relative;
            top: 5px;
          }

          .pri-num {
            width: 50%;
            display: inline-block;
            text-align: right;
            position: relative;

            p {
              display: inline-block;
              font-size: 12px;
              color: #8830cc;
              padding-right: 5px;

              span {
                font-size: 16px;
              }
            }

            .my-box {
              display: inline-block;
              width: 100px;
              border: 0;
              padding: 0;
              z-index: 8;
              background-color: inherit;
              -webkit-transition: all 1s ease-out;
              -moz-transition: all 1s ease-out;
              vertical-align: text-bottom;

              input {
                margin: 0;
                width: 100%;
                color: #666;
                border: none;
                padding: 0 22px;
                height: 30px;
                box-sizing: border-box;
                display: inline-block;
                -webkit-text-fill-color: #666;
                -webkit-opacity: 1;
                position: relative;
                top: 6px;
              }

              .van-stepper__input[disabled] {
                background: transparent !important;
              }

              button {
                position: absolute;
                z-index: 6;
                padding: 0;
                height: 100%;
                border: none;
                font-size: 22px;
                background-color: #fff0;

                &:before {
                  top: 4px;
                  width: auto;
                  height: auto;
                  background-color: transparent;
                  font-family: rolormd !important;
                }
              }

              .van-stepper__minus {
                color: #aaa;

                &:before {
                  content: "\ea10";
                }

                &.van-stepper__minus--disabled {
                  opacity: 0.6;
                }
              }

              &.hide-minus-btn {
                .van-stepper__minus {
                  opacity: 0;
                }

                .van-stepper__input {
                  opacity: 0;
                  background-color: transparent !important;
                }
              }
            }
          }
        }
      }
    }

    .mui-bar-tab {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      height: 50px;
      background-color: #fff;
      border-top: 1px solid #f1f1f1;

      .car-but {
        position: absolute;
        top: -9px;
        left: 15px;
        z-index: 996;
        color: #fff;
        width: 48px;
        height: 48px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        background-color: #fb69a5;

        .box {
          border-radius: 50%;
          border: 3px solid #fff;
        }

        [class^="icon-"] {
          font-size: 22px;
          line-height: 44px;
        }

        .mui-badge {
          position: absolute;
          top: -5px;
          right: -8px;
          color: #fb69a5;
          font-size: 12px;
          line-height: 1;
          padding: 3px 6px;
          border-radius: 100px;
          display: inline-block;
          border: 1px solid #fb69a5;
          background-color: #fff;
        }
      }

      .cart_logo_box {
        position: absolute;
        left: 5px;
        bottom: 12px;
        display: flex;
        width: 50px;
        height: 50px;

        .img_box {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #ff48a4;
        }

        .cart_logo_img1 {
          width: 50px;
          height: 50px;
        }

        .cart_logo_img2 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 23px;
          height: 23px;
        }

        .cart_num_box {
          position: absolute;
          z-index: 1;
          right: -4px;
          top: 0;
          background-color: #fff;
          border: 1px solid #ff48a4;
          border-radius: 10px;
          padding: 0 3px;
          color: #ff48a4;
        }
      }

      .car-text {
        position: absolute;
        bottom: 9px;
        left: 58px;
        color: #999;
        font-size: 12px;

        .car-money {
          color: #ff48a4;
          text-align: start;
          font-size: 16px;

          s {
            color: #666;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .pink {
          color: #ff48a4;
        }
      }

      .mui-btn {
        font-size: 14px;
        position: absolute;
        top: 8px;
        right: 10px;
        color: #fff;
        border: none;
        padding: 7px 23px;
        letter-spacing: 2px;
        border-radius: 33px;
        background-color: #fcb11b;
      }
    }

    &.not-allowed {
      .mui-bar-tab {
        .mui-btn {
          background-color: #666;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .content .head .vouchers .tit {
    font-size: 12px;
  }

  .content .head .vouchers .tit em {
    font-size: 10px;
  }

  .content .head .vouchers .tit span {
    font-size: 10px;
  }
}

@media screen and (max-width: 330px) {
  .footer .order-data .list-cont .list .pri-num .my-box {
    width: 92px;
  }

  .footer .order-data .list-cont .list .pri-num p {
    font-size: 10px;
  }
}
</style>